import {firestore} from "../configs/firebase";

class Support{
  // eslint-disable-next-line no-unused-vars
  constructor(props) {
    // super(props);
  }

}

function Model(){
  this.content = '';
  this.from = '';
  this.from_admin = false;
  this.created_at = firestore.FieldValue.serverTimestamp()
}

export const SupportModel = Model;
export default Support;