<template>
    <div>
        <!-- App Capsule -->
        <div id="appCapsule">

            <!--            <div class="message-divider">-->
            <!--                Friday, Sep 20, 10:40 AM-->
            <!--            </div>-->
            <template v-for="(message, idx) in messages">

                <div class="message-item mt-2" :key="'msg_'+idx" v-if="!message.data.from_admin">
                    <img src="../../../assets/img/sample/avatar/avatar5.jpg" alt="avatar" class="avatar" style="border-radius: 50%">
                    <div class="content">
                        <div class="title">
                            {{message.data.from}}
                        </div>
                        <div class="bubble">
                            {{message.data.content}}
                        </div>
                        <div class="footer text-start">{{getReadableDatetime(message.data.created_at)}}</div>
                    </div>
                </div>

                <div class="message-item user" :key="'msg_'+idx" v-else>
                    <div class="content">
                        <div class="bubble">
                            {{message.data.content}}
                        </div>
                        <div class="footer">{{getReadableTime(message.data.created_at)}}</div>
                    </div>
                </div>

            </template>

            <div class="col-12 text-center mt-5" v-if="loading">
                <i>Fetching conversations</i>
            </div>
            <div class="col-12 text-center mt-5" v-else-if="messages.length <= 0">
                <p>Empty conversation</p>
            </div>

        </div>
        <!-- * App Capsule -->

        <!-- chat footer -->
        <support-message/>
        <!-- * chat footer -->
    </div>
</template>

<script>
import supportMessage from "../../../components/admin/supportMessage";
import methodsMixin from "../../../utils/methodsMixin";
import {supports_store} from "../../../configs/firebase";

export default {
    name: "Support",
    data(){
        return {
            messages: [],
            loading: false,
            listener: null,
        }
    },
    mixins: [methodsMixin],
    methods: {
        async fetchConversations(){
            this.loading = true;
            const userId = this.$route.params.userId;
            try{
                // let user = await supports_store.doc(userId).get();
                // if(user.exists){
                this.listener = supports_store
                    .doc(userId)
                    .collection('messages')
                    .orderBy('created_at')
                    .onSnapshot(async (snapshot) => {
                        let tmp_arr = [];
                        snapshot.forEach(doc=>{
                            tmp_arr.push({
                                id: doc.id,
                                data: doc.data()
                            })
                        });
                        this.messages = tmp_arr;
                        var scr = $('#appCapsule')[0].scrollHeight;
                        $('#appCapsule').animate({scrollTop: scr},2000);
                        await this.$store.dispatch('support/markMsgAsRead', userId);
                    });
                // }
            }catch (e){
                console.log(e)
            }
            this.loading = false;
        }
    },
    components: {
        supportMessage
    },
    mounted() {
        this.fetchConversations()
    },
    beforeRouteLeave(to, from, next){
        if(this.listener !== null){
            this.listener()
        }
        next()
    }
}
</script>

<style scoped>
.avatar{
    width: 30px;
    height: 20px;
    border-radius: 0;
}

#appCapsule{
    /*max-height: 500px;*/
    overflow: hidden scroll;
}
</style>